import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"

// Elements
import SEO from "../elements/seo"
import Layout from "../elements/layout"
import Slider from "../elements/slider"
import Subscribe from "../elements/subscribe"
import Heading from "../elements/heading"

const RewardsPage = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "rewards" })}
        keywords={[
          `${intl.formatMessage({ id: "title" })}`,
          `${intl.formatMessage({ id: "seo" })}`,
        ]}
        description="At New Macau Casino, we love to spoil our guests! Join our rewards programme to receive exciting complimentary rewards."

      />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={intl.formatMessage({ id: "rewards_title" })}
        sliderSubtitle={intl.formatMessage({ id: "rewards_subtitle" })}
        objectPosition="65% 50%"
      />
      <Subscribe />
      <Heading
        title={intl.formatMessage({ id: "coming_soon" })}
        description={intl.formatMessage({ id: "stay_tuned" })}
      />
    </Layout>
  )
}

export default injectIntl(RewardsPage)
