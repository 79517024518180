import React from "react"
import PropTypes from "prop-types"

export default function heading(props) {
  return (
    <div className="heading">
      <h1 className="heading__title">{props.title}</h1>
      <p className="heading__description">{props.description}</p>
    </div>
  )
}

heading.defaultProps = {
    title: `Title`,
    description: `Description.`
}

heading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
