import React, { Component } from "react"
import { navigate } from "gatsby-link"
import { injectIntl } from "gatsby-plugin-intl"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class subscribe extends Component {
  state = {
    email: "",
  }

  handleChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "subscribe",
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  render() {
    const { intl } = this.props
    return (
      <div className="subscribe">
        <h5>{intl.formatMessage({ id: "subscribe" })}</h5>
        <p>{intl.formatMessage({ id: "subscribe_message" })}</p>
        <form
          className="subscribe__form"
          name="subscribe"
          method="post"
          action="/thankyou/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="contact" />
          <label style={{ display: "none" }}>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
          <label htmlFor="email" className="subscribe__label">
            {intl.formatMessage({ id: "email" })}
          </label>
          <input
            className="subscribe__input"
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={this.handleChange}
            value={this.state.email}
          />
          <button className="subscribe__button" type="submit">
            {intl.formatMessage({ id: "send" })}
          </button>
        </form>
      </div>
    )
  }
}

export default injectIntl(subscribe)
